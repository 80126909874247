const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID || '';


const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID, // Reemplaza con tu clientId
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://arjchat.arjanvier.com" // Reemplaza con tu redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage", // Puede ser 'localStorage' si prefieres persistencia
    storeAuthStateInCookie: false, // Configura en true si tienes problemas en IE
  },
};


export default msalConfig;