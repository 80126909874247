import React, { useState, useRef, useEffect } from "react";
import PromptMessageComponent from "./PromptMessageComponent";
import PromptInputComponent from "./PromptInputComponent";
import PDFViewer from "./PdfViewerComponent";
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { PromptTemplate } from "../../Entity/PromptTemplate";
import { ChatMessage } from "../../Entity/ChatMessage";
import ENDPOINTS from "../../config";
import CardList from "../DocumentComponents/CardList";



interface ChatComponentProps {
  promptTemplates: PromptTemplate[];
  setPromptTemplates: React.Dispatch<React.SetStateAction<PromptTemplate[]>>;
  messages: ChatMessage[];
  updateMessages: (messages: ChatMessage[]) => void;
  historyId: number | null; // Añadir el historyId como prop
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  promptTemplates,
  setPromptTemplates,
  messages,
  updateMessages,
  historyId // Usar el historyId aquí
}) => {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (historyId !== null) {
      // Aquí puedes realizar acciones con el historyId
      console.log(`Current historyId: ${historyId}`);
    }
  }, [historyId]);

  useEffect(() => {
  }, [messages, updateMessages]);

  // Crear una referencia para la última respuesta para mover el scroll
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  // Mueve el scroll después de agregar un mensaje
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  const isBase64Image = (str: string) => {
    const regex = /^[A-Za-z0-9+/]+[=]{0,2}$/;

    return regex.test(str);
  };
  // Función para manejar el envío de un nuevo mensaje
  const handleSendMessage = async (newMessage: string, selectedFile: any,configuration:any) => {
    try {
      setIsLoading(true);
      // Agregar el nuevo mensaje del usuario a los mensajes
      updateMessages([...messages, { id: 0, sender: "user", message: newMessage, images: [] }]);
      const token = localStorage.getItem("token");
      // Realizar la petición a la API de OpenAI con el nuevo mensaje
      const formData = new FormData();
      if (selectedFile) {
        formData.append('file', selectedFile);
      }
      formData.append('chat_message', JSON.stringify({ message: newMessage, sender: "user", id: 0 }));
      formData.append('chat_history_id', JSON.stringify(historyId || 0));
      formData.append('chat_history_message', JSON.stringify(messages));
      formData.append('configuration', JSON.stringify(configuration));
      

      const response = await fetch(ENDPOINTS.ASK_QUESTION, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: formData,
      });

      const data = await response.json();
      const answer = data.answer.answer; // Extraer la respuesta del objeto
      //obtener solo las imagenes de data.answer.contex donde page_content sea una imagen
      const images: { src: string, source: string }[] = [];
      const reference: string[] = [];
      let fileNameSet = new Set();
      const chat_history_id = data.answer.chat_history_id;
      const context = data.answer.context
      try {
        for (let i = 0; i < context.length; i++) {
          try {
            if (isBase64Image(context[i].page_content)) {
              const imageSource = context[i].metadata['source'];
              const fileName = imageSource.split('\\').pop().split('/').pop();
              images.push({ src: context[i].page_content, source: fileName });
            }
            else {
              const pathFile = context[i].metadata['source']
              //si el pathfile es una URL dejarla asi 
              if (pathFile.startsWith('http')) {
                reference.push(pathFile);
                continue;
              }

              const fileName = pathFile.split('\\').pop().split('/').pop();
              if (!fileNameSet.has(fileName)) {
                fileNameSet.add(fileName);
                reference.push(fileName);
              }
            }
          } catch (error) {
            console.error("Error al convertir el contexto en imagenes:", error);
          }
        }
      } catch (error) {
        console.error("Error al convertir el contexto en imagenes:", error);
      }

      // Agregar la respuesta del backend a los mensajes
      const user_message_id = data.answer.user_message_id;
      const assistant_message_id = data.answer.assistant_message_id;
      const messages_new = [
        ...messages,
        { id: user_message_id, sender: "user", message: newMessage, chat_history_id: chat_history_id } as ChatMessage,
        { id: assistant_message_id, sender: "assistant", message: answer, images: images, reference: reference, chat_history_id: chat_history_id } as ChatMessage
      ];
      console.log(messages_new)

      updateMessages(messages_new);

    } catch (error) {
      console.error("Error al manejar el streaming de OpenAI:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={50} minSize={20} maxSize={80}>
        <div className="flex h-[100vh] w-full flex-col rounded-lg">

          <PromptMessageComponent messages={messages} lastMessageRef={lastMessageRef} isLoading={isLoading} />
          <PromptInputComponent
            onSendMessage={handleSendMessage}
            promptTemplates={promptTemplates}
            setPromptTemplates={setPromptTemplates}
            isLoading={isLoading}          />

        </div>
      </Panel>

      <PanelResizeHandle />

      <Panel defaultSize={30} minSize={35} maxSize={60}>
        <div className=" bg-slate-50 py-4 dark:border-slate-200/10 dark:bg-slate-900 bg-black-200 text-black">
          <CardList />
        </div>
      </Panel>
    </PanelGroup>

  );
};

export default ChatComponent;
