import React, { useState } from "react";
import ENDPOINTS from "../config";

interface UserSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLanguageChange: (language: string) => void;
}

const UserSettingsModal: React.FC<UserSettingsModalProps> = ({ isOpen, onClose, onLanguageChange }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLanguage(event.target.value);
  };

  const handleAccept = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(ENDPOINTS.USER_SETTING, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
           "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          lang: selectedLanguage,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Notify parent component of the language change
      onLanguageChange(selectedLanguage);
      onClose();
    } catch (error) {
      console.error('Error updating user settings:', error);
      // Handle error (show message to user, etc.)
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-slate-50 dark:bg-slate-700 rounded-lg shadow-sm p-6 max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">User Settings</h2>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300 mb-2">Language</label>
          <select
            value={selectedLanguage}
            onChange={handleLanguageSelect}
            className="block w-full mt-1 rounded-md  bg-gray-900 border-gray-900 hover:bg-blue-900 dark:hover:bg-gray-900 border-b  dark:border-white-500"
          >
             <option value="en_US">English (US)</option>
            <option value="es_ES">Spanish / Español</option>
            <option value="fr_FR">French / Français</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
          >
            Close
          </button>
          <button
            onClick={handleAccept}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserSettingsModal;
