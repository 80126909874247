import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import pt_PT from "@react-pdf-viewer/locales/lib/pt_PT.json";

interface PDFModalProps {
    open: boolean;
    onClose: () => void;
    documentUrl: string;
    documentName: string;
}

const PDFViewer: React.FC<PDFModalProps> = ({ open, onClose, documentUrl, documentName }) => {
    const lowerCaseDocumentName = documentName.toLowerCase();

    const isPDF = lowerCaseDocumentName.endsWith('.pdf');
    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(lowerCaseDocumentName);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [],
    });

    //Ver la documentacion del componente
    //https://react-pdf-viewer.dev/docs/options/


    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth     >
            <DialogTitle className="placeholder:text-gray-400 focus:ring-2   focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500">{documentName}</DialogTitle>
            <DialogContent style={{ height: '100vh' }} className="placeholder:text-gray-400 focus:ring-2   focus:ring-blue-500 dark:bg-gray-900 dark:text-white dark:focus:ring-blue-500">
            {isPDF ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
                        <div style={{ height: '100vh', overflow: 'auto' }}>
                            <Viewer
                             theme="dark"
                                fileUrl={documentUrl}
                                localization={pt_PT}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                        </div>
                    </Worker>
                ) : isImage ? (
                    <img
                        src={documentUrl}
                        alt={documentName}
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                    />
                ) : (
                    <iframe
                        title={documentName}
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(documentUrl)}&embedded=true`}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        sandbox="allow-scripts allow-same-origin allow-popups"
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PDFViewer;
