import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import ENDPOINTS from '../../config'; // Importamos la configuración centralizada
import { DocumentRAG } from '../../Entity/DocumentRAG';
import { useTranslation } from 'react-i18next'; 
import PDFViewer from '../ChatComponents/PdfViewerComponent';

const fadeInAnimation = {
  animation: 'fadeIn 1.5s ease-in-out',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
};

const ScrollableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100vh',
  padding: '10px',
  marginTop: '0px',
  alignItems: 'center',
});

const StyledCard = styled('li')(({ theme }) => ({
  display: 'grid',
  maxWidth: 'sm',
  cursor: 'pointer',
  justifyItems: 'center',
  borderRadius: '10px',
  marginBottom: '20px',
  ...fadeInAnimation,
  opacity: 0,
}));

const CardList: React.FC = () => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [documents, setDocuments] = useState<DocumentRAG[]>([]); // Use the Document type here
  const [selectedDocument, setSelectedDocument] = useState<DocumentRAG | null>(null); // Estado para el documento seleccionado
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true); // Estado para controlar si hay más datos



  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    fetchDocuments(page, searchTerm);

    return () => clearTimeout(timeout);
  }, [page, searchTerm]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      if (hasMoreData && !isLoading) {
        setPage(prevPage => prevPage + 1);
      }
    }, 40000);

    return () => clearInterval(interval);
  }, [hasMoreData, isLoading]);


  // Fetch the documents here (example data fetching)
  const fetchDocuments = async (page: number, search: string) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const response = await fetch(`${ENDPOINTS.DOCUMENT_RAG}?page=${page}&search=${search}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    const data: DocumentRAG[] = await response.json();
    if (data.length === 0) {
      setHasMoreData(false); // Si no hay datos, establecer hasMoreData en false
    }
    if (page === 1) {
      setDocuments(data); // Reemplaza los documentos al buscar
    } else {
      setDocuments(prevDocuments => [...prevDocuments, ...data]);
    }
    setIsLoading(false);
  };



  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1);
    setHasMoreData(true); // Restablecer hasMoreData al buscar
  };
  const handleCardClick = (document: DocumentRAG) => {
    setSelectedDocument(document);
  };

  const handleCloseModal = () => {
    setSelectedDocument(null); // Limpiar el documento seleccionado al cerrar el modal
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <ScrollableContainer onScroll={handleScroll}>
      <div className="relative w-full mb-5">
        <h1 className="text-xl font-bold text-slate-800 dark:text-slate-200">{t('document.documents')}</h1>
        <input
          type="text"
          className="w-full rounded-lg border border-slate-300 bg-slate-50 p-3 pr-10 text-sm text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200"
          placeholder={t('document.search_by_title')} 
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          type="button"
          className="absolute bottom-2 right-2.5 rounded-lg p-2 text-sm text-slate-500 hover:text-blue-700 focus:outline-none sm:text-base"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            aria-hidden="true"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 9h8"></path>
            <path d="M8 13h5"></path>
            <path d="M11.008 19.195l-3.008 1.805v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5"></path>
            <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            <path d="M20.2 20.2l1.8 1.8"></path>
          </svg>
          <span className="sr-only">{t('document.search_documents')}</span>
        </button>
      </div>

      <ul className="grid grid-cols-1 gap-6 text-slate-900 dark:text-slate-200 sm:grid-cols-2 lg:grid-cols-2">
        {documents.map((document, index) => (
          <StyledCard
            key={document.id}
            className="group col-span-1 grid justify-items-center rounded-lg"
            style={{
              animationDelay: isVisible ? `${index * 0.1}s` : '0s',
              opacity: isVisible ? 1 : 0,
            }}
            onClick={() => handleCardClick(document)}
          >
            <div
              className="h-64 w-full rounded-lg bg-slate-300 bg-cover bg-center shadow-md"
              style={{
                backgroundImage: `url(${document.preview_image ? `data:image/png;base64,${document.preview_image}` : 'https://via.placeholder.com/150x400'})`
              }}
            ></div>
            <div
              className="-mt-10 w-11/12 overflow-hidden rounded-lg border-2 border-transparent bg-slate-50 shadow-lg transition-colors group-hover:bg-blue-600 dark:bg-slate-900"
            >
              <h3
                className="p-2 text-center text-sm font-semibold text-slate-800 transition-colors group-hover:text-slate-50 dark:text-slate-200"
              >
                {document.name}
              </h3>
              <div className="flex p-2">
                <span className="truncate text-sm text-slate-500 transition-colors group-hover:text-slate-200">
                  {document.description}
                </span>
              </div>
            </div>
          </StyledCard>
        ))}
      </ul>
      {isLoading && <div className="text-center text-sm font-semibold text-slate-800 transition-colors group-hover:text-slate-50 dark:text-slate-200">{t('document.loading_more_documents')}</div>}
      {selectedDocument && (
        <PDFViewer
          open={Boolean(selectedDocument)}
          onClose={handleCloseModal}
          documentUrl={ENDPOINTS.PDF_VIEWER(selectedDocument.id || 0)}
          documentName={selectedDocument.name}
        />
      )}
    </ScrollableContainer>
  );
};

export default CardList;
