// config.ts

const API_ROOT = process.env.REACT_APP_API_ROOT || 'http://localhost:8000';
const Odoo_ROOT = process.env.REACT_APP_Odoo_ROOT || 'http://localhost:8015';
const ODOO_TOKEN = '3453534KUIGJHYGVKJLYUFCYHJKKLJHVC'


const ENDPOINTS = {
  CHAT_MESSAGE_EVALUATION: (messageId: string) => `${API_ROOT}/chat_history/chat_message/${messageId}/evaluation`,
  PROMPT_TEMPLATE: (id?: number) => id ? `${API_ROOT}/prompt_template/${id}` : `${API_ROOT}/prompt_template`,
  CHAT_HISTORY: (skip = 0, limit = 10) => `${API_ROOT}/chat_history/?skip=${skip}&limit=${limit}`,
  CHAT_MESSAGES: (historyId: number) => `${API_ROOT}/chat_history/chat_message/${historyId}`,
  DOCUMENT_RAG: `${API_ROOT}/document_rag`,
  ASK_QUESTION:  `${API_ROOT}/chat_history/ask_question/`,
  AUTH_LOGIN: `${API_ROOT}/auth/login`,
  AUTH_GOOGLE_CALLBACK: (token: string) => `${API_ROOT}/auth/google/callback?code=${token}`,
  AUTH_MICROSOFT: (token: string) => `${API_ROOT}/auth/microsoft?token=${token}`,
  UPLOAD_AUDIO: `${API_ROOT}/document_rag/upload_audio/`,
  REGISTER: `${API_ROOT}/auth/register`,
  UPLOAD_FILE: `${API_ROOT}/document_rag/upload_file/`,
  AUTH_ME: `${API_ROOT}/auth/me`,
  PDF_VIEWER: (docId: any) => `${Odoo_ROOT}/documents/pdf/${docId}?token=${ODOO_TOKEN}`,
  USER_SETTING: `${API_ROOT}/user/user_setting`,
  AUTH_MICROSOFT_CALLBACK: (token: string) => `/auth/microsoft/callback?token=${token}`,

 
};

export default ENDPOINTS;


