// src/component/RegisterComponent.tsx

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ENDPOINTS from "../../config";

const RegisterComponent: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(ENDPOINTS.REGISTER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        navigate("/");
      } else {
        const data = await response.json();
        setError(data.detail || "Error al registrarse");
      }
    } catch (error) {
      console.error("Error en el registro:", error);
      setError("Error en el registro");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <h2 className="mb-4 text-2xl font-semibold">Registro</h2>
      <form onSubmit={handleRegister} className="w-full max-w-sm">
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">Email</label>
          <input
            type="email"
            className="w-full px-3 py-2 border rounded-md"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 text-sm font-medium">Password</label>
          <input
            type="password"
            className="w-full px-3 py-2 border rounded-md"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="mb-4 text-red-500">{error}</p>}
        <button type="submit" className="w-full py-2 text-white bg-blue-500 rounded-md">
          Registrarse
        </button>
      </form>
      <p className="mt-4 text-sm">
        ¿Ya tienes una cuenta? <a href="/login" className="text-blue-500">Inicia sesión</a>
      </p>
    </div>
  );
};

export default RegisterComponent;
