import {  memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";

export const MemoizedReactMarkdown = memo(
  (props: any) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[ rehypeRaw]}
      {...props}
    />
  ),
  (prevProps, nextProps) => prevProps.children === nextProps.children
);