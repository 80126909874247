import React, { useState, useEffect } from 'react';
import { PromptTemplate } from '../../Entity/PromptTemplate';

interface PromptListComponentProps {
  onPromptClick: (template: PromptTemplate) => void;
  onNewPromptClick: () => void;
  promptTemplates: PromptTemplate[];
}

const PromptListComponent: React.FC<PromptListComponentProps> = ({ onPromptClick, onNewPromptClick, promptTemplates }) => {
  
  return (
    <div
    className="h-1/2 space-y-4 overflow-y-auto border-b border-slate-300 px-2 py-4 dark:border-slate-700"
  >
      <div className="mx-2 mb-5">
        <h1 className="text-xl font-bold text-slate-800 dark:text-slate-200">Prompt Templates</h1>
        <form>
          <label htmlFor="prompt-input" className="sr-only">Search Prompt</label>
          <div className="relative">
            <input
              id="search-prompts"
              type="text"
              className="w-full rounded-lg border border-slate-300 bg-slate-50 p-3 pr-10 text-sm text-slate-800 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:border-slate-700 dark:bg-slate-900 dark:text-slate-200"
              placeholder="Search Prompts"
              required
            />
            <button
              type="button"
              className="absolute bottom-2 right-2.5 rounded-lg p-2 text-sm text-slate-500 hover:text-blue-700 focus:outline-none sm:text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                aria-hidden="true"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 9h8"></path>
                <path d="M8 13h5"></path>
                <path d="M11.008 19.195l-3.008 1.805v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5"></path>
                <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                <path d="M20.2 20.2l1.8 1.8"></path>
              </svg>
              <span className="sr-only">Search Prompt</span>
            </button>
          </div>
        </form>
      </div>
      <div className="my-4 h-80 space-y-4 overflow-y-auto px-2">
        {promptTemplates.map((template, index) => (
          <button
            key={index}
            className="flex w-full flex-col gap-y-2 rounded-lg px-3 py-2 text-left transition-colors duration-200 hover:bg-blue-500 hover:text-white focus:outline-none dark:hover:bg-blue-600"
            onClick={() => onPromptClick(template)}
          >
            <h1 className="text-sm font-medium capitalize text-slate-700 transition-colors duration-200 dark:text-slate-200">
              {template.name}
            </h1>
            <p className="text-xs text-slate-500 transition-colors duration-200 dark:text-slate-400">
              {template.description}
            </p>
          </button>
        ))}
      </div>
</div>
  );
};

export default PromptListComponent;
